import React, { forwardRef, memo, useState, useImperativeHandle } from 'react'
import SlotSelector from 'components/SlotSelector'
import { IconButton } from '@material-ui/core'
import { ExpandMoreTwoTone as ExpandMoreTwoToneIcon } from '@material-ui/icons'
import useStyles from './styles'
import displayTime from 'utils/displayTime'

const SlotTimetable = memo(
  forwardRef(({ selectedSlotTimetable, selectorRef, value, ...props }, ref) => {
    const [showSelector, setShowSelector] = useState(false)
    const classes = useStyles()

    const onExpand = () => setShowSelector(!showSelector)

    useImperativeHandle(selectorRef, () => ({
      close: () => setShowSelector(false),
    }))
    return (
      <div className={classes.root} ref={selectorRef}>
        {value && (
          <>
            <label className={classes.label}>
              Selected Date &amp; Time: {displayTime(value.start, value?.end, false)}
              <IconButton onClick={onExpand}>
                <ExpandMoreTwoToneIcon />
              </IconButton>
            </label>
            {showSelector && <SlotSelector ref={ref} {...props} selectedSlotTimetable={selectedSlotTimetable} />}
          </>
        )}
      </div>
    )
  })
)

export default SlotTimetable
